// Libraries
import React from 'react';

// Components
import { setData } from '../../functions/storage';

// maak hier alle constanten aan die je in actions wilt gebruiken
export const SET_PARAM = 'SET_PARAM';
export const RESET_PARAM = "RESET_PARAM";
export const SET_PARAM_CACHE = 'SET_PARAM_CACHE';
export const SET_MODULE = 'SET_MODULE';
export const SET_DATA = 'SET_DATA';
export const SET_DEBUG = 'SET_DEBUG';
export const SET_ADVIESSTAP_ID = 'SET_ADVIESSTAP_ID';
export const SET_FIRST_VERFIJNSTAP_ID = 'SET_FIRST_VERFIJNSTAP_ID';
export const SET_DISABLE_BACK_BUTTON = 'SET_DISABLE_BACK_BUTTON';

// Alle initiële waardes
export const commonInitialState = {
  module: '',
  param: {
    "goto": 0,
    "moduleNaam": '',
    "chain":[]
  },
  param_cache: {
    "goto":0,
    "moduleNaam": '',
    "chain":[]
  },
  data: {},
  disable_back_button: 0,
  debug: false,
  adviesstap_id: false,
  first_verfijnstap_id: false
}

// actions, om fouten te voorkomen gebruik je uitsluitende constanten
export const commonActions = ( state, action ) => {

  switch ( action.type ) {
    case SET_MODULE:
      if( state.module != action.value ){
        // overgeschakeld naar een nieuwe module, initiele parameters gebruiken
        //console.log( action.type );
        setData("module", action.value);
        setData("param", commonInitialState.param);
        return {...state, module: action.value, param: commonInitialState.param }
      }
      //console.log( action.type, "ongewijzigd");
      return state;
      break;
    case SET_PARAM:
      if( JSON.stringify( state.param ) != JSON.stringify( action.value ) ){
        //console.log( action.type );
        setData("param", action.value);
        return { ...state, param: action.value };
      }
      //console.log( action.type, "ongewijzigd");
      return state;
      break;
    case RESET_PARAM:
      //console.log( action.type );
      setData("param", commonInitialState.param);
      return { ...state, param: commonInitialState.param }
    case SET_PARAM_CACHE:
      //if( JSON.stringify( state.param_cache ) != JSON.stringify( action.value ) ){
        //console.log( action.type );
        setData("param_cache", action.value);
        return { ...state, param_cache: action.value };
      //}
      //console.log( action.type, "ongewijzigd");
      return state;
      break;
    case SET_DATA:
      if( JSON.stringify( state.data ) != JSON.stringify( action.value ) ){
       // console.log( action.type );
        return { ...state, data: action.value };
      }
      console.log( action.type, "ongewijzigd");
      return state;
      break;
    case SET_DEBUG:
      if( JSON.stringify( state.debug ) != JSON.stringify( action.value ) ){
        //console.log( action.type );
        setData("debug", action.value);
        return { ...state, debug: action.value };
      }
      //console.log( action.type, "ongewijzigd");
      return state;
      break;
    case SET_DISABLE_BACK_BUTTON:
      if( JSON.stringify( state.disable_back_button ) != JSON.stringify( action.value ) ){
        //console.log( action.type );
        setData("disable_back_button", action.value);
        return { ...state, disable_back_button: action.value };
      }
      //console.log( action.type, "ongewijzigd");
      return state;
      break;
    case SET_ADVIESSTAP_ID:
      if( JSON.stringify( state.adviesstap_id ) != JSON.stringify( action.value ) ){
        //console.log( action.type );
        setData("adviesstap_id", action.value);
        return { ...state, adviesstap_id: action.value };
      }
      //console.log( action.type, "ongewijzigd");
      return state;
      break;
    case SET_FIRST_VERFIJNSTAP_ID:
      if( JSON.stringify( state.first_verfijnstap_id ) != JSON.stringify( action.value ) ){
        //console.log( action.type );
        setData("first_verfijnstap_id", action.value);
        return { ...state, first_verfijnstap_id: action.value };
      }
      //console.log( action.type, "ongewijzigd");
      return state;
      break;
    default:
      return state;
  }
}
